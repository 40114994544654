import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

import { ContentfulDiscount } from 'src/models/discounts';

type Props = {
  discount?: ContentfulDiscount;
};

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const FeaturedDiscount: React.FC<Props> = ({ discount }): JSX.Element => {
  const { title, partner, description, thumbnail } = discount;

  return (
    <>
      <div className="images">
        <img
          className="featured-banner"
          src={thumbnail?.image?.url}
          alt={thumbnail?.image?.description}
          data-cy="banner"
        />
        <img
          className="partner"
          src={partner?.thumbnail?.image?.url}
          alt={partner?.thumbnail?.image?.description}
          data-cy="partner"
        />
      </div>
      <div className="content-wrap">
        <h5 className="title" data-cy="title">
          {title}
        </h5>
        <ResponsiveEllipsis
          className="content"
          text={description}
          maxLine="2"
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </div>
    </>
  );
};

export { FeaturedDiscount };
