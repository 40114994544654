import React from 'react';

import { Buttons } from 'src/components/discounts/CTAs';
import { Translate } from 'src/components/Translate';
import { Membership } from 'src/components/benefits/useBenefitsPageQueries';
import { JoinCta } from 'src/components/JoinCta';
import { useFreeTrial } from 'src/hooks/useFreeTrial';

import { AlreadyMemberButton } from '../already-member-button';

import styles from './Banner.module.scss';

type Props = {
  ready: boolean;
  membership?: Membership;
};

const Banner: React.FC<Props> = ({ ready, membership }): JSX.Element => {
  const { isEligibleForFreeTrial } = useFreeTrial();
  const hasJoinCta = ready && !membership;

  return (
    <div className={styles.wrapper} data-test="banner">
      <Translate
        as="h1"
        className={`${styles.heading} text-display_2`}
        resourceKey="partner-offers.banner.heading"
        data-test="banner-heading"
      />
      <Translate
        as="p"
        className={styles.description}
        resourceKey="partner-offers.banner.description"
        data-test="banner-description"
      />
      <Buttons size="large">
        {hasJoinCta && (
          <div className="mr-6 mb-4">
            <JoinCta />
          </div>
        )}
      </Buttons>
      {hasJoinCta && isEligibleForFreeTrial && (
        <Translate
          as="p"
          className="lg-plus-pt-4 pb-0 font-size-x-small"
          data-cy="free-trial-disclaimer"
          resourceKey={'free-trial.cta.disclaimer'}
          useHtml
        />
      )}
      <AlreadyMemberButton />
    </div>
  );
};

export default Banner;
