import React from 'react';

import { ContentfulDiscount } from 'src/models/discounts';

type Props = {
  discount?: ContentfulDiscount;
};

const DiscountItem: React.FC<Props> = ({ discount }): JSX.Element => {
  const { title, partner, description } = discount;

  return (
    <>
      <div className="partner" data-test="partner">
        <div
          style={{ backgroundImage: `url(${partner?.thumbnail?.image?.url})` }}
          role="img"
          aria-label={partner?.thumbnail?.image?.description}
        />
      </div>
      <div className="content">
        <h5 className="title" data-test="title">
          {title}
        </h5>
        <div className="desc" data-test="description">
          {description}
        </div>
      </div>
    </>
  );
};

export { DiscountItem };
