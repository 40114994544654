import React, { useState, useEffect, useRef } from 'react';
import { withPrefix } from 'gatsby';
import { Modal } from '@hagerty/react-components';

import { ContentfulDiscount } from 'src/models/discounts';
import { Membership } from 'src/components/benefits/useBenefitsPageQueries';
import { useTrackingContext } from 'src/components/TrackingContext';

import { DiscountDetails } from './discount-details';
import { DiscountItem } from './discount-item';
import { FeaturedDiscount } from './featured-discount';
type Props = {
  discount?: ContentfulDiscount;
  isSelected?: boolean;
  isFeatured?: boolean;
  ready: boolean;
  membership?: Membership;
  isAuthenticated: boolean;
};

const Discount: React.FC<Props> = ({
  discount,
  isSelected = false,
  isFeatured = false,
  ready,
  membership,
  isAuthenticated,
}): JSX.Element => {
  const { partner, id } = discount;
  const [open, setOpen] = useState(false);
  const { trackModalView } = useTrackingContext();
  const ref = useRef(null);

  useEffect(() => {
    if (isSelected) handleOpen(null, true);
  }, [isSelected]);

  useEffect(() => {
    // call this function once set & if modal opens
    if (open && trackModalView) trackModalView(`${discount.partner.title} | ${discount.title}`);
  }, [open, trackModalView]);

  useEffect(() => {
    const listener = (event: any) => {
      if (
        event.target == ref?.current.nextSibling ||
        event.target.closest('.button-close') ||
        event.target.classList.contains('modal__container')
      )
        handleClose();
    };
    ref?.current.nextSibling.addEventListener('click', listener);
    return () => {
      ref?.current.nextSibling.removeEventListener('click', listener);
    };
  }, [ref]);

  const handleOpen = (event, isNotClick = false) => {
    window.history.replaceState('', '', withPrefix(`/partner-offers/?partner_filter=${partner.partnerFilter}`));
    if (event?.target.id !== ref?.current.id || isNotClick) ref?.current.click();
    setOpen(true);
  };

  const handleClose = () => {
    ref?.current.nextSibling.click();
    ref?.current.nextSibling.firstChild.click(); // Ensure click is triggered on 'modal__container'
    window.history.replaceState('', '', withPrefix('/partner-offers/'));
    setOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleOpen(event, true);
    }
  };

  return (
    <li
      className={`partner-offer list-style-none no-padding ${isFeatured && 'featured'}`}
      data-test={`${isFeatured ? 'featured-offer-card' : 'partner-offer'} ${discount.partner.partnerFilter}`}
    >
      <div
        className={isFeatured ? 'inner' : 'discount'}
        onClick={handleOpen}
        role="button" // let screen reader know this is clickable
        tabIndex={0} // make element focusable with keyboard
        id={`${id}-${isFeatured}`}
        onKeyDown={handleKeyDown}
        ref={ref}
      >
        {!isFeatured && <DiscountItem discount={discount} />}
        {isFeatured && <FeaturedDiscount discount={discount} />}
      </div>
      <Modal id={`${id}-${isFeatured}`} isAnimated>
        <DiscountDetails offer={discount} ready={ready} membership={membership} isAuthenticated={isAuthenticated} />
      </Modal>
    </li>
  );
};

export { Discount };
