import React from 'react';
import { useTranslation } from 'react-i18next';

import { Translate } from 'src/components/Translate';
import { ContentfulDiscount } from 'src/models/discounts';
import { Membership } from 'src/components/benefits/useBenefitsPageQueries';
import LoadingContainer from 'src/components/LoadingContainer';

import { Buttons, LogIn, JoinTheClub, Redeem } from 'src/components/discounts/CTAs';
import styles from './styles.scss';

interface Props {
  offer: ContentfulDiscount;
  ready: boolean;
  membership?: Membership;
  isAuthenticated: boolean;
}

const DiscountDetails: React.FC<Props> = ({ offer, ready, membership, isAuthenticated }): JSX.Element => {
  const { t } = useTranslation();
  const codeRedemption = 'discount code';

  const getRedeemTips = (offer) => {
    if (!isAuthenticated) return t('partner-offers.details.redeem-tips-unauthenticated');
    if (ready && isAuthenticated && !membership) return t('partner-offers.details.redeem-tips-non-member');
    return offer.redemptionMethod.toLowerCase() === codeRedemption && offer.redemptionMethodTextOnModal
      ? offer.redemptionMethodTextOnModal
      : offer.redemptionMethod;
  };
  const redeemText = getRedeemTips(offer);

  const getRedeemUrl = (offer: ContentfulDiscount) => {
    const { url, discountCode, requiresMembershipCode, urlParameter } = offer;
    return requiresMembershipCode ? `${url}${url.match(/\/$/) ? '' : '/'}?${urlParameter}=${discountCode}` : url;
  };

  const getRedeemMethod = (offer: ContentfulDiscount) =>
    offer.redemptionMethod.toLowerCase() === codeRedemption ? 'code' : 'auto';
  return (
    <div className="details">
      <div className="header">
        <img src={offer?.thumbnail?.image?.url} alt={offer?.thumbnail?.image?.description} data-test="offer-image" />
      </div>
      <div className="body">
        <div className="partner">
          <img
            src={offer.partner?.thumbnail?.image?.url}
            alt={offer.partner?.thumbnail?.image?.description}
            data-test="partner"
          />
        </div>
        <h4 className="title" data-test="title">
          {offer.title}
        </h4>
        <p className="description" data-test="description">
          {offer.description}
        </p>
        {offer.termsConditions && (
          <p className="terms">
            <Translate
              useHtml
              resourceKey="partner-offers.details.terms-and-conditions"
              values={{ brand: offer.partner.title, url: offer.termsConditions.url }}
            />
          </p>
        )}
      </div>
      <div className="footer">
        {ready ? (
          <>
            {getRedeemTips(offer) && (
              <p className={styles.redeem} data-cy="redeem-description">
                {redeemText}
              </p>
            )}
            <Buttons>
              <JoinTheClub ready={ready} membership={membership} />
              <LogIn />
              <Redeem
                method={getRedeemMethod(offer)}
                url={getRedeemUrl(offer)}
                buttonText={offer.buttonTextOnModal}
                ready={ready}
                membership={membership}
              />
            </Buttons>
          </>
        ) : (
          <LoadingContainer height={90} />
        )}
      </div>
    </div>
  );
};

export { DiscountDetails };
