import React, { Dispatch, SetStateAction, useMemo } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from '@hagerty/react-components';

import { ContentfulDiscountCategory } from 'src/models/discounts';
import styles from './Categories.module.scss';

type SetStateType<T> = Dispatch<SetStateAction<T>>;

interface CategoriesProps {
  categories: ContentfulDiscountCategory[];
  onChange: SetStateType<string>;
  value: string;
}

interface CategoryItemProps {
  id: string;
  value: string;
  label: string;
  isSelected: boolean;
  clickHandler: (string) => void;
}

const CategoryItem: React.FC<CategoryItemProps> = ({ id, label, value, clickHandler, isSelected }): JSX.Element => (
  <li className={`${styles.item} list-style-none`} id={id}>
    <Button
      aria-selected={isSelected}
      className={cx(styles.itemButton, isSelected ? styles.activeItem : '')}
      onClick={() => clickHandler(value)}
      testId="filter-item"
      type="button"
    >
      {label}
    </Button>
  </li>
);

const Categories: React.FC<CategoriesProps> = (props): JSX.Element => {
  const { t } = useTranslation();
  const { categories, onChange, value } = props;

  const normalized = useMemo(() => {
    const copy = [...categories];
    copy.unshift({ category: t('partner-offers.categories.all'), id: 'all' } as ContentfulDiscountCategory);
    return copy;
  }, [categories]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.listWrapper}>
        <ul className={styles.list} data-test="filter-list">
          {normalized.map((c: ContentfulDiscountCategory) => (
            <CategoryItem
              id={`category-${c.id}`}
              key={c.id}
              value={c.category}
              label={c.category}
              isSelected={value === c.category}
              clickHandler={onChange}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Categories;
