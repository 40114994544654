import React, { useEffect, useMemo, useState } from 'react';
import { PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTreatments } from '@splitsoftware/splitio-react';

import { Split, Treatment } from 'src/constants/Split';
import { isAgent } from 'src/api/client';
import useMembership from 'src/api/useMembership';
import useProfile from 'src/api/useProfile';
import { Discount } from 'src/features/discounts';
import Categories from 'src/components/discounts/Categories';
import Banner from 'src/components/discounts/Banner';
import { Translate } from 'src/components/Translate';
import { useAutoLogin } from 'src/hooks/useAutoLogin';
import { usePreventAgentAccess } from 'src/hooks/usePreventAgentAccess';
import { ContentfulDiscount } from 'src/models/discounts';
import all from 'src/models/contentful-discounts.json';
import categories from 'src/models/contentful-discount-categories.json';
import { useTracking } from 'src/components/TrackingContext';
import isBrowser from 'src/helpers/isBrowser';
import { isReady } from 'src/components/SplitContext/isReady';
import LoadingContainer from 'src/components/LoadingContainer';
import { trackPageLoad } from 'src/services/tracker';
import { useUserTracker } from 'src/hooks/useUserTracker';
import useBenefitsPageQueries, { Membership } from 'src/components/benefits/useBenefitsPageQueries';
import { MainFooter } from 'src/features/footer';
import FadeIn from 'src/components/FadeIn';

import styles from './index.module.scss';

type OfferListProps = {
  title?: string;
  offers: ContentfulDiscount[];
  partnerSelected?: boolean;
  ready: boolean;
  membership: Membership;
  isAuthenticated: boolean;
};

const Featured: React.FC<OfferListProps> = ({ offers, ready, membership, isAuthenticated }) => (
  <>
    <Translate
      resourceKey="partner-offers.featured.title"
      as="h2"
      className={styles.title}
      data-test="featured-title"
    />
    <ul data-test="featured-list" className={cx(styles.featuredCards, 'no-padding')}>
      {offers.map((offer) => (
        <Discount
          key={offer.id}
          discount={offer}
          ready={ready}
          membership={membership}
          isFeatured
          isAuthenticated={isAuthenticated}
        />
      ))}
    </ul>
  </>
);

const featured = all.filter((item) => item.isFeature);

export const Discounts: React.FC<PageProps> = (): JSX.Element => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [afterPageRedirect, setAfterPageRedirect] = useState(false);
  const [isInitialCategory, setIsInitialCategory] = useState(true);
  const treatments = useTreatments([Split.FeaturePartnerOfferPageRedirection]);
  const isPartnerOfferPageRedirection = treatments[Split.FeaturePartnerOfferPageRedirection].treatment === Treatment.On;
  const { t } = useTranslation();
  usePreventAgentAccess();
  useAutoLogin();
  const user = useUserTracker();
  useTracking('discounts_view', null, true);
  const { ready, membership, isAuthenticated } = useBenefitsPageQueries();
  const splitIsReady = isReady();

  if (isAgent) {
    // Force fetch membership data from the server
    useMembership({ fetchPolicy: 'network-only' });
    useProfile({ fetchPolicy: 'network-only' });
  }

  // FIXME: use categories id instead of UI strings
  const AllFilter = t('partner-offers.categories.all');
  const [category, setCategory] = useState<string>(AllFilter);
  const noFilter = category === AllFilter;

  let params;
  let categoryFilter;
  let partnerFilter;

  if (isBrowser) {
    params = new URLSearchParams(document.location.search);
    categoryFilter = params.get('category_filter');
    partnerFilter = params.get('partner_filter');
  }

  // Set category filter if partner filter match or if just category filter matches.
  useEffect(() => {
    let category;
    let partner;

    if (!isPartnerOfferPageRedirection) return;

    setAfterPageRedirect(true);

    if (categoryFilter) category = categories.find((category) => category.categoryFilter === categoryFilter);
    if (partnerFilter) partner = all.find((discount) => discount.partner.partnerFilter === partnerFilter);

    if (partner && category && partner.category.category !== category.category) return;

    if (category) setCategory(category.category);
  }, [isPartnerOfferPageRedirection]);

  const discounts = useMemo<ContentfulDiscount[]>(() => {
    if (noFilter) {
      return all;
    }

    return all.filter((item) => item.category.category === category);
  }, [category, all]);

  const listTitle = useMemo<string>(() => {
    return noFilter ? t('partner-offers.list.title.all') : category;
  }, [category, noFilter]);

  const openPartnerOffer = (offer: ContentfulDiscount): boolean => {
    if (!isPartnerOfferPageRedirection || afterPageRedirect) return false;

    if (offer.partner.partnerFilter === partnerFilter) return true;

    return false;
  };

  const handleDiscountView = () => {
    trackPageLoad({
      _tag_name: 'discounts_view',
      ...user,
      filter: category,
      featured_discounts: discounts.map((d) => d.id),
    });
  };

  useEffect(() => {
    if (!isInitialCategory) {
      handleDiscountView();
    }
  }, [category]);

  useEffect(() => {
    if (user && !hasLoaded && discounts.length) {
      handleDiscountView();
      setHasLoaded(true);
      setIsInitialCategory(false);
    }
  }, [user, hasLoaded, discounts]);

  if (!splitIsReady) {
    return (
      <div className="full-page-loading">
        <LoadingContainer />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('head.title.partner-offers')}</title>
        <meta name="description" content={t('head.meta-description.partner-offers')} />
        <meta name="robots" content="all" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <main className={styles.warp}>
        {!splitIsReady || !ready ? (
          <div className="full-page-loading">
            <LoadingContainer />
          </div>
        ) : (
          <FadeIn duration={0.4} up>
            <Banner ready={ready} membership={membership} />
            <Categories categories={categories} value={category} onChange={setCategory} />
            {noFilter && (
              <Featured offers={featured} ready={ready} membership={membership} isAuthenticated={isAuthenticated} />
            )}
          </FadeIn>
        )}

        <h2 data-test="offers-title" className={styles.title}>
          {listTitle}
        </h2>

        <ul className={cx(styles.discountList, 'no-padding')} data-cy="offers-list">
          {discounts.map((offer) => (
            <Discount
              key={offer.id}
              discount={offer}
              isSelected={openPartnerOffer(offer)}
              ready={ready}
              membership={membership}
              isAuthenticated={isAuthenticated}
            />
          ))}
        </ul>
      </main>

      <MainFooter />
    </>
  );
};

export default Discounts;
